export interface IDirectLineState {
  token: string;
  shouldRestart: boolean;
  tokenStatus: TokenStatus;
}

export enum TokenStatus {
  EMPTY = "empty",
  FETCHING = "fetching",
  FETCHED = "fetched",
  REJECTED = "rejected",
}
