import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchDirectLineToken } from "../../services/token";
import { RootState } from "..";

import { IDirectLineState, TokenStatus } from "./types";

// export const fetchToken = createAsyncThunk<{ token: string }, void, { state: RootState }>(
//   "directLine/fetchToken",
//   async (_, { rejectWithValue, getState }) => {
//     try {
//       const lang = getState().langreg.language;
//       const reg = getState().langreg.region;
//       const user = getState().user.data;

//       const res = await fetchDirectLineToken(user, reg, lang);
//       return res;
//     } catch (err) {
//       const error = err as { response: { data: string } };
//       return rejectWithValue(error.response.data);
//     }
//   },
// );

export const fetchToken = createAsyncThunk<{ token: string }, void, { state: RootState }>(
  "directLine/fetchToken",
  async (_, { rejectWithValue, getState }) => {
    try {
      const token = process.env.REACT_APP_DIRECTLINE_SECRET;
      if (!token) {
        throw new Error("Direct Line token not found.");
      }
      return { token };
    } catch (err) {
      const error = err as { response: { data: string } };
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState: IDirectLineState = {
  shouldRestart: false,
  token: "",
  tokenStatus: TokenStatus.EMPTY,
};

const directLineSlice = createSlice({
  name: "directLine",
  initialState,
  reducers: {
    restart(state) {
      state.shouldRestart = true;
    },
    restarted(state) {
      state.shouldRestart = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.shouldRestart = true;
      state.tokenStatus = TokenStatus.FETCHED;
    });
    builder.addCase(fetchToken.pending, state => {
      state.tokenStatus = TokenStatus.FETCHING;
    });
    builder.addCase(fetchToken.rejected, state => {
      state.tokenStatus = TokenStatus.REJECTED;
    });
  },
});

export const { restart, restarted } = directLineSlice.actions;

export const selectDirectLineData = (state: RootState): IDirectLineState => state.directLine;

export const directLineReducer = directLineSlice.reducer;