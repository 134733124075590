import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

import { IUIState } from "./types";

const initialState: IUIState = {
  languageMenuOpen: false
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openLanguageMenu(state) {
      state.languageMenuOpen = true;
    },
    closeLanguageMenu(state) {
      state.languageMenuOpen = false;
    },
  },
});

export const {
  openLanguageMenu,
  closeLanguageMenu,
} = uiSlice.actions;

export const selectLanguageMenuOpen = (state: RootState): boolean => state.ui.languageMenuOpen;

export const uiReducer = uiSlice.reducer;
