import axios from 'axios'
import UserContext from "../contexts/UserContext"

declare module 'axios' {
	export interface AxiosRequestConfig {
		crossdomain: boolean;
	}
}


export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
export const AnonymousUser = {name: 'Anonymous', id: 'anonymous@bot', auth: false}

export const getAuth = async () => {
	
	var ret = AnonymousUser;
	if(process.env.REACT_APP_USE_AUTHENTICATION !== 'true') return ret;
	await axios({
		method: 'GET',
		url: process.env.REACT_APP_IS_PRODUCTION === 'true' ? '/.auth/me' : 'me.json', 
		responseType: 'json',
		crossdomain: true,
		withCredentials: false
	}).then(result => {
		if (result.status === 200 && result.data !== null) {
			let userClaim = result.data[0].user_claims.find((e: any) => e.typ === 'name');
			ret = {name: userClaim ? userClaim.val : "Unknown", id: result.data[0].user_id, auth: true};
		}
	}, error => console.log(error));
	return ret;
}
