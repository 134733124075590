export interface IUserData {
  id: string;
  name: string;
}

export enum UserDataStatus {
  EMPTY = "empty",
  FETCHING = "fetching",
  FETCHED = "fetched",
  REJECTED = "rejected",
  RETRY = "retry",
}

export interface IUserState {
  dataStatus: UserDataStatus;
  data: IUserData;
}