
export const TOKEN_API = process.env.REACT_APP_TOKEN ?? "";

export const TARGET_INDICATOR = process.env.REACT_APP_TARGET_INDICATOR ?? "";

export const LANGUAGES_ENABLED = process.env.REACT_APP_LANGUAGES_ENABLED ?? "";

export const APP_REGION = process.env.REACT_APP_REGION ?? "DCA" as string;

export const PUBLIC_URL = process.env.PUBLIC_URL ?? "";

export const USE_FALLBACK_USER_DATA = process.env.REACT_APP_USE_AUTHENTICATION ?? "false";

export const DIRECTLINE_SECRET = process.env.REACT_APP_DIRECTLINE_SECRET ?? "";