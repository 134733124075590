import React, { PropsWithChildren } from "react";
import { ThreeDots } from "react-loader-spinner";
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  height: "100vh";
  width: "100vw";
  z-index: 1500;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
`

type LoadingPageProps = PropsWithChildren<{
  animation?: boolean;
}>;

const LoadingPage = (props: LoadingPageProps): React.ReactElement => {
  const { children, animation = true } = props;

  const color = "white"
  const miliseconds = 1200000; //2 min

  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => setShow(false), miliseconds);
    return () => clearTimeout(timeout);
  }, []);

  return show ? (
    <Container>
      {animation && <ThreeDots color={color} height={30} width={100} />}
      <div>
        <p color={color}>
          {children}
        </p>
      </div>
    </Container>
  ) : (
    <div />
  );
};

export default LoadingPage;
