import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { USE_FALLBACK_USER_DATA } from "constants/constant";
import { getAuth } from "services/auth";
import { RootState } from "store";

import { UserDataStatus, IUserData, IUserState } from "./types";

const fallbackUserData: IUserData = {
  id: 'ano@nymous',
  name: 'Anonymous',
};



export const thunkFetchUserData = createAsyncThunk("user/fetchUserData", async (_, { rejectWithValue }) => {
  if (USE_FALLBACK_USER_DATA.toLocaleLowerCase() === "true") return fallbackUserData;
  try {
    return await getAuth();
  } catch (error) {
    const errorMessage = error as string;

    if (errorMessage === "usefallback") {
      return fallbackUserData;
    } else return rejectWithValue(errorMessage);
  }
});

const initialState: IUserState = {
  data: {} as IUserData,
  dataStatus: UserDataStatus.EMPTY
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(thunkFetchUserData.fulfilled, (state, action) => {
        state.data = action.payload as IUserData;
        state.dataStatus = UserDataStatus.FETCHED;
      })
      .addCase(thunkFetchUserData.pending, state => {
        state.dataStatus = UserDataStatus.FETCHING;
      })
      .addCase(thunkFetchUserData.rejected, (state, action) => {
        state.data = {} as IUserData;
        state.dataStatus = action.payload === "retry" ? UserDataStatus.RETRY : UserDataStatus.REJECTED;
      })
  },
});

export const selectUserData = (state: RootState): IUserData => state.user.data;
export const selectUserDataStatus = (state: RootState): string => state.user.dataStatus;

export const userReducer = userSlice.reducer;
