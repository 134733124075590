import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingPage from "components/common/LoadingPage";
const Logout = React.lazy(() => import("components/logout/Logout"));
const Splash = React.lazy(() => import("components/splash/Splash"));
const App = React.lazy(() => import("App"));


const Router = (): React.ReactElement => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <React.Suspense fallback={<LoadingPage>Page is loading</LoadingPage>}>
        <Routes>
            <Route path="/" element={<Splash/>} />
            <Route path="webchat" element={<App/>} />
            {process.env.REACT_APP_USE_AUTHENTICATION === 'true' &&
            <Route path="loggedout" element={<Logout/>} />}
        </Routes>
      </React.Suspense>
  </BrowserRouter>
  );
};

export default Router;
