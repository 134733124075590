import { combineReducers } from "@reduxjs/toolkit";

import { directLineReducer } from "./directLine/slice";
import { langregReducer } from "./langreg/slice";
import { uiReducer } from "./ui/slice";
import { userReducer } from "./user/slice";

const reducers = {
  user: userReducer,
  directLine: directLineReducer,
  langreg: langregReducer,
  ui: uiReducer
};

export default combineReducers(reducers);
