import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

import { ILangRegState } from "./types";

const initialState: ILangRegState = {
  language: "en",
  region: "DCA",
  query: ""
};

const langregSlice = createSlice({
  name: "langreg",
  initialState,
  reducers: {
    changeLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    changeRegion(state, action: PayloadAction<string>) {
      state.region = action.payload;
    },
    sendQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const {
  changeLanguage,
  changeRegion,
  sendQuery
} = langregSlice.actions;

export const selectLanguage = (state: RootState): string => state.langreg.language;
export const selectRegion = (state: RootState): string => state.langreg.region;
export const selectQuery = (state: RootState): string => state.langreg.query;

export const langregReducer = langregSlice.reducer;
