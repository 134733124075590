import './index.css';
import * as serviceWorker from './serviceWorker';
import { createRoot } from "react-dom/client";

import Router from 'router/Router';

import store from './store';
import { Provider } from "react-redux";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <Router />
    </Provider> 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
